import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import { Route, Switch } from "react-router-dom";
import Minting from "./Pages/Minting";

function App() {
  return (
    <Switch>
        <Route exact path="/">
          <Minting />
        </Route>
        <Route exact path="/mint">
          <Minting />
        </Route>
        <Route exact path="/chillCubes">
          <Minting />
        </Route>
    </Switch>
  );
}

export default App;
